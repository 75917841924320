import EtherLogo from 'src/config/assets/token_eth.svg'
import { EnvironmentSettings, ETHEREUM_NETWORK, NetworkConfig } from 'src/config/networks/network.d'

const baseConfig: EnvironmentSettings = {
  txServiceUrl: 'http://18.156.146.204:8100/api/v1',
  safeAppsUrl: 'https://apps.gnosis-safe.io',
  gasPriceOracle: {
    url: 'https://ethgasstation.info/json/ethgasAPI.json',
    gasParameter: 'average',
  },
  rpcServiceUrl: 'https://rpc.q.org',
  networkExplorerName: 'Q Explorer',
  networkExplorerUrl: 'https://explorer.q.org',
  networkExplorerApiUrl: 'https://explorer.q.org/api',
  gnosisSafeContractAddress: '0x343d86df3f302FD6E53b4cbdE5709F91c2dcC1c0',
  proxyFactoryContractAddress: '0xb23b1BFcbF16e2A7bA98E2c2c20C6925632BA5ad',
}

const q: NetworkConfig = {
  environment: {
    dev: {
      ...baseConfig,
    },
    staging: {
      ...baseConfig,
    },
    production: {
      ...baseConfig,
    },
  },
  network: {
    id: ETHEREUM_NETWORK.Q,
    backgroundColor: '#E8673C',
    textColor: '#ffffff',
    label: 'Q',
    isTestNet: false,
    nativeCoin: {
      address: '0x000',
      name: 'Q',
      symbol: 'Q',
      decimals: 18,
      logoUri: EtherLogo,
    },
  },
}

export default q
