import local from './local'
import mainnet from './mainnet'
import rinkeby from './rinkeby'
import xdai from './xdai'
import energy_web_chain from './energy_web_chain'
import volta from './volta'
import q from './q'
import qdevnet from './qdevnet'
import qtestnet from './qtestnet'

export default {
  local,
  mainnet,
  rinkeby,
  xdai,
  energy_web_chain,
  volta,
  q,
  qdevnet,
  qtestnet,
}
